import * as React from 'react';

import Seo from '../components/global/seo';
import Layout from '../components/global/layout';
import Contact from '../components/contact/contact';

const ContactPage = () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  );
};

export const Head = () => <Seo title='Contact' />;

export default ContactPage;
