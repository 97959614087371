import React, { useEffect, useState } from 'react';

import CONFIG from '../../utils/config.js';
import { required, length, email } from '../../utils/validator';

import 'styles/pages/contact.scss';

const Contact = () => {
  const [state, setState] = useState({
    loginForm: {
      name: {
        value: '',
        valid: false,
        validators: [required, length({ min: 2 })],
      },
      email: {
        value: '',
        valid: false,
        validators: [required, email],
      },
      subject: {
        value: '',
        valid: false,
        validators: [required],
      },
      message: {
        value: '',
        valid: false,
        validators: [required, length({ min: 5 })],
      },
    },
    formIsValid: false,
  });
  const [btnClassState, setBtnClassState] = useState('');
  const [btnTextState, setBtnTextState] = useState('Submit');
  const [selectClass, setSelectClass] = useState('');

  const clearForm = () => {
    setSelectClass('');
    setState({
      loginForm: {
        name: {
          value: '',
          valid: false,
          validators: [required, length({ min: 2 })],
        },
        email: {
          value: '',
          valid: false,
          validators: [required, email],
        },
        subject: {
          value: '',
          valid: false,
          validators: [required],
        },
        message: {
          value: '',
          valid: false,
          validators: [required, length({ min: 5 })],
        },
      },
      formIsValid: false,
    });
  };

  const handleChange = (e) => {
    const input = e.target.name;
    if (!selectClass && input === 'subject') {
      setSelectClass('change');
    }
    const value = e.target.value;
    setState((prevState) => {
      let isValid = true;
      for (const validator of prevState.loginForm[input].validators) {
        isValid = isValid && validator(value);
      }
      const updatedForm = {
        ...prevState.loginForm,
        [input]: {
          ...prevState.loginForm[input],
          valid: isValid,
          value: value,
        },
      };
      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }
      return {
        loginForm: updatedForm,
        formIsValid: formIsValid,
      };
    });
  };

  useEffect(() => {
    if (state.formIsValid) {
      setBtnClassState('valid');
    } else {
      setBtnClassState('');
    }
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      setBtnTextState('Submit');
    }, 5000);
  }, [btnTextState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.formIsValid) return;

    setBtnTextState('Sending...');
    const res = await fetch(`${CONFIG.apiUrl}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: state.loginForm.name.value,
        email: state.loginForm.email.value,
        subject: state.loginForm.subject.value,
        message: state.loginForm.message.value,
      }),
    });
    const { message } = await res.json();
    if (res.status === 200) {
      clearForm();
      setBtnTextState(message);
    } else {
      setBtnTextState(message);
    }
  };

  return (
    <div className='contact'>
      <h1 className='title'>Contact Us</h1>
      <div className='contact-container'>
        <div className='contact-form'>
          <div className='contact-form-container'>
            <form className='form'>
              <div className='user-info'>
                <div className='input-container'>
                  <label htmlFor='name'>Name</label>
                  <input
                    type='text'
                    placeholder='Mobile Air Service'
                    name='name'
                    value={state.loginForm.name.value}
                    onChange={handleChange}
                    spellCheck='false'
                  />
                </div>
                <div className='input-container'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    placeholder='josh.wilson@mobileairservice.com'
                    name='email'
                    value={state.loginForm.email.value}
                    onChange={handleChange}
                    spellCheck='false'
                  />
                </div>
              </div>
              <div className='user-details'>
                <div className='input-container'>
                  <label htmlFor='subject'>Subject</label>
                  <select
                    name='subject'
                    className={selectClass}
                    value={state.loginForm.subject.value}
                    onChange={handleChange}
                  >
                    <option value='' disabled hidden>
                      Please select a subject
                    </option>
                    <optgroup>
                      <option value='General inquires'>General inquires</option>
                      <option value='Appraisals'>Appraisals</option>
                      <option value='Inspections'>Inspections</option>
                      <option value='Aircraft repair / maintenance'>
                        Aircraft repair / maintenance
                      </option>
                      <option value='Decals & placards'>
                        Decals & placards
                      </option>
                      <option value='Specialized structural repairs'>
                        Specialized structural repairs
                      </option>
                      <option value='Other'>Other</option>
                    </optgroup>
                  </select>
                </div>
                <div className='input-container'>
                  <label htmlFor='message'>Message</label>
                  <textarea
                    name='message'
                    rows='8'
                    placeholder='Enter message here'
                    value={state.loginForm.message.value}
                    onChange={handleChange}
                    spellCheck='false'
                  ></textarea>
                </div>
              </div>
              <button
                onClick={handleSubmit}
                className={['form-submit', btnClassState].join(' ')}
              >
                {btnTextState}
              </button>
            </form>
          </div>
        </div>
        <div className='contact-direct'>
          <div className='contact-direct-info'>
            <h4>Direct contact</h4>
            <div className='contact-direct-link'>
              <a href='mailto:josh.wilson@mobileairservice.com'>
                <i className='uil uil-envelope'></i>
              </a>
              <p>
                <a
                  className='contact-link'
                  href='mailto:josh.wilson@mobileairservice.com'
                >
                  josh.wilson@mobileairservice.com
                </a>
              </p>
            </div>
            <div className='contact-direct-link'>
              <a href='tel:204-803-5473'>
                <i className='uil uil-phone'></i>
              </a>
              <p>
                <a className='contact-link' href='tel:204-803-5473'>
                  (204) 803-5473
                </a>
              </p>
            </div>
            <div className='contact-direct-link'>
              <a href='tel:204-626-3350'>
                <i className='uil uil-print'></i>
              </a>
              <p>
                <a className='contact-link' href='tel:204-626-3350'>
                  (204) 626-3350
                </a>
              </p>
            </div>
            <div className='contact-direct-link'>
              <a
                rel='noreferrer'
                noopener='true'
                target='_blank'
                href='https://www.google.com/maps/place/MobileAir+Service/@50.061077,-97.0442139,17z/data=!4m13!1m7!3m6!1s0x52ea667c969ba1e1:0x58ef8d7409d5c1c1!2s407+Helicopter+Dr,+Saint+Andrews,+MB+R1A+3P7!3b1!8m2!3d50.061077!4d-97.0420199!3m4!1s0x52ea667ce91df613:0x19bef95a4b6445c7!8m2!3d50.0613765!4d-97.0418767'
              >
                <i className='uil uil-estate'></i>
              </a>
              <a
                className='contact-link'
                rel='noreferrer'
                noopener='true'
                target='_blank'
                href='https://www.google.com/maps/place/MobileAir+Service/@50.061077,-97.0442139,17z/data=!4m13!1m7!3m6!1s0x52ea667c969ba1e1:0x58ef8d7409d5c1c1!2s407+Helicopter+Dr,+Saint+Andrews,+MB+R1A+3P7!3b1!8m2!3d50.061077!4d-97.0420199!3m4!1s0x52ea667ce91df613:0x19bef95a4b6445c7!8m2!3d50.0613765!4d-97.0418767'
              >
                <div className='contact-address'>
                  <p>407 Helicopter Dr</p>
                  <p>Saint Andrews, MB R1A 3P7</p>
                  <p>Canada</p>
                </div>
              </a>
            </div>
          </div>
          <div className='contact-hours'>
            <h4>Hours</h4>
            <div className='contact-hour'>
              <p>Sunday</p>
              <p>CLOSED</p>
            </div>
            <div className='contact-hour'>
              <p>Monday</p>
              <p>8am - 5pm</p>
            </div>
            <div className='contact-hour'>
              <p>Tuesday</p>
              <p>8am - 5pm</p>
            </div>
            <div className='contact-hour'>
              <p>Wednesday</p>
              <p>8am - 5pm</p>
            </div>
            <div className='contact-hour'>
              <p>Thursday</p>
              <p>8am - 5pm</p>
            </div>
            <div className='contact-hour'>
              <p>Friday</p>
              <p>8am - 5pm</p>
            </div>
            <div className='contact-hour'>
              <p>Saturday</p>
              <p>CLOSED</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
